table {
  width: 100%;
  border-spacing: 0;
}

thead {
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--secondary);
  margin-bottom: 3rem;
  padding-bottom: 3rem;

  tr {
    text-align: left;
    th {
       padding-bottom: 1rem;
    }
  }
}

td {
  // border-bottom: 1px solid var(--light);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.order-list {
  padding: 2rem;
}

.order-list .buttons {
  text-align: right;
}

.order-list button {
  padding: 0.25rem 2rem;
  font-size: 1rem;
}
