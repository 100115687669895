@import '_base.scss';

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--super-light);
  overflow-x: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  padding: 0rem 3rem;
  flex: 1;
}

// header,
main,
footer .body {
  max-width: 1200px;
  margin: auto;
  width: 100%;
}

section > h1 {
  color: var(--rhythm);
  font-size: 2.5rem;
  font-weight: bold;
  border-bottom: 5px solid var(--pacific-blue);
  width: fit-content;
  margin-bottom: 2rem;
}

main > h1 {
  color: var(--rhythm);
  font-size: 3rem;
  font-weight: bold;
  width: fit-content;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

h2 {
  color: var(--oxford-blue);
  font-size: 2rem;
  padding-bottom: 0.5rem;
  padding-top: 1rem;
  width: fit-content;
  margin: auto;
}

section:not(:first-child) {
  margin-top: 3em;
}

a.darkBg {
  color: var(--pacific-blue);
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color:var(--pacific-blue);
}

a.darkBg:hover {
  text-decoration: none;
}

a {
  color: var(--pacific-blue);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color:var(--pacific-blue);
}

.center {
  text-align: center;
}

// button {
//   background-color: var(--primary);
//   color: var(--light);
//   padding: 0.5rem 2rem;
//   border-radius: 10px;
//   font-size: 1rem;
//   font-weight: bold;
//   border-width: 0;
//   box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.4);
//   cursor: pointer;
//   outline: none;
// }

// .large-button {
//   @extend button;
//   padding: 1rem 2.5rem;
//   &-cta {
//     @extend .large-button;
//     text-align: center;
//     margin-top: 3em;
//     margin-bottom: 3em;
//   }
// }

.spread-apart {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


ul.breadcrumb {
  display: flex;
  list-style-type: none;
  padding: 0 0 2rem 0;
  margin: 0 0 0 0;

  li+li:before {
    content: ">";
    padding: 0 10px;
  }
}

@import "components/_list.scss";
@import "_header.scss";
@import "_homepage.scss";
@import "_footer.scss";
@import "_splash-screen.scss";
@import "_cms.scss";
@import "_table.scss";
@import "_race.scss";


.padding-05rem{
  padding: 0.5rem;
}

.address {
  padding: 2rem;
  font-size: 1.25rem;
  display: flex;
  justify-content: space-between;
}


.searchBox {
    padding-bottom: 1rem;
}

.cart {
  .option {
    font-size: 0.75rem;
  }

  .option > td {
    padding-top: 0;
  }

  .option > td:first-child {
    padding-left: 0.5rem;
  }

}

.buttonBox{
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
}

button.exportButton { 
  margin-top: 1rem;
  margin-bottom: 1rem;
}