@import '../_base.scss';

// .input-group {
//   text-align: left;
//   position: relative;
// }

form {
  // padding: 0 5rem;
  display: grid;
  // position: relative;
  // top: 50%;
  // left: 50%;
  // width: 400px;
  // padding: 40px;
  // transform: translate(-50%, -50%);
  // background: rgba(0,0,0,.5);
  // box-sizing: border-box;
  // box-shadow: 0 15px 25px rgba(0,0,0,.6);
  // border-radius: 10px;
}

.radio-bar {
  margin-top: 1rem;

  input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }

  label {
    display: inline-block;
    background-color: #ddd;
    padding: 0.5rem 0.5rem;
    font-family: sans-serif, Arial;
    // font-size: 16px;
    border: 1px solid #444;
    border-radius: 4px;
  }

  input[type="radio"]:checked + label {
    background-color: var(--secondary);
    border-color: var(--primary);
    color: var(--light);
  }
}

.input-group {
  // text-align: left;
  //   display: block;
  position: relative;
  margin-bottom: 1.5rem;
  width: 100%;
  text-align: left;

  input {
    margin-top: 0.25rem;
    // width: 100%;
    outline: none;
    border: none;
    font-size: 1rem;
    color: var(--oxford-blue);
    border-bottom: 2px solid var(--pacific-blue);
  }

  input:read-only {
    background-color: var(--alice-blue);
  }

  input[type="text"]::placeholder {
    font-size: 1rem;
    color: var(--alice-blue);
  }

  input[type="password"]::placeholder {
    font-size: 1rem;
    color: var(--alice-blue);
  }

  select {
    margin-top: 0.25rem;
    // width: 100%;
    outline: none;
    border: none;
    font-size: 1rem;
    color: var(--oxford-blue);
    border-bottom: 2px solid var(--pacific-blue);
    background-color: transparent ;
  }

  select.disabled {
    background-color: var(--alice-blue) ;
    opacity: 1;
  }

  label {
    width: 100%;
    text-align: left;
    font-size: 1rem;
    padding-bottom: 3rem;
    color: var(--oxford-blue);
    font-weight: bold;
  }

  label+input {
    margin-left: 1rem;
  }

  label+select {
    margin-left: 1rem;
  }

  label.required::before {
    content: "* ";
    color: red;
  }

  .hint {
    // padding: 0 0 2rem 0;
    color: var(--oxford-blue);
    font-size: 0.75rem;
  } 

  .error {
    color: red;
    padding: 2px 4px;
    font-size: 0.75rem;
  }
} 

// input {
//   border: 0;
//   border-bottom: 1px solid var(--dark);
//   // border-color: var(--primary);
// }

// .input-group {
//   input[type="text"] {
//     width: 100%;
//     // padding: 1rem 0;
//     // font-size: 16px;
//     // color: #fff;
//     margin-top: 2rem;
//     // border: none;
//     // border-bottom: 1px solid var(--dark);
//     outline: none;
//     background: transparent;
//   }

//   input[type="text"] ~ label {
//     position: absolute;
//     top: 0;
//     left: 0;
//     padding-top: 1.75rem;
//     // padding-top: 20px;
//     // font-size: 16px;
//     // color: #fff;
//     pointer-events: none;
//     transition: 0.5s;
//   }

//   input[type="text"]:focus ~ label,
//   input[type="text"]:empty ~ label {
//     top: -1rem;
//     left: 0;
//     color: var(--primary);
//     font-size: 0.75rem;
//   }
// }
