header.homepage:before {
  background-image: url(../images/background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  content: "";
  z-index: -1;
}

header.homepage {
  height: 100vh;
}

#splash-screen {
  margin-left: 3rem;
  margin-right: 3rem;
  // margin-top: 40vh;
  display: flex;
  justify-content: space-around;
  align-content: center;
  height: 100%;
}

#splash-screen h1 {
  color: var(--pacific-blue);
  font-size: 5rem;
  text-shadow: 2px 2px 2px rgba($color: #000000, $alpha: 0.3);
}

#splash-screen h2 {
  color: var(--rhythm);
  font-size: 1.5rem;
}

#splash-screen div.cta {
  align-self: center;
  text-align: right;
}

// #splash-screen .cta button {
//   @extend .large-button;
//   width: 70%;
// }

// #splash-screen .cta button a {
//   color: var(--light)
// }

.spash-screen-text {
  align-self: center;
}

.description-body {
  display: flex;
  // margin-top: 1rem;
}

.description-body:before {
  content: "";
  background-image: url(../images/smart.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  width: 100%;
  margin-right: 3rem;
}

.description-list p {
  color: var(--oxford-blue);
  padding-left: 2rem;
  margin-bottom: 1rem;
}

.description-list h2::first-letter {
  font-size: 3.4rem;
}

#features h1 {
  margin-left: auto;
  margin-right: auto;
}

.features-body {
  display: flex;
  justify-content: space-between;
}

.features-group h2 {
  font-size: 1.75rem;
  margin-bottom: 1rem;
}

.features-list h3 {
  color: var(--rhythm);
  font-size: 1.25rem;
}

.features-list p {
  color: var(--oxford-blue);
}

.features-list > div:not(:last-child) {
  padding-bottom: 1.5rem;
}

.features-list > div {
  position: relative;
  padding-left: 3rem;
}
.features-list > div::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  content: "";
  background-repeat: no-repeat;
  background-size: contain;
  width: 2.5rem;
  height: 2.5rem;
}

.feature-registrations::before {
  background-image: url(../images/icons/iconmonstr-id-card-17.svg);
}

.feature-categories::before {
  background-image: url(../images/icons/iconmonstr-id-card-22.svg);
}

.feature-check-in::before {
  background-image: url(../images/icons/iconmonstr-edit-9.svg);
}

.feature-items-list::before {
  background-image: url(../images/icons/iconmonstr-text-28.svg);
}

.feature-android-app::before {
  background-image: url(../images/icons/iconmonstr-android-os-1.svg);
}

.feature-sponsors::before {
  background-image: url(../images/icons/iconmonstr-clipboard-13.svg);
}

.feature-logos::before {
  background-image: url(../images/icons/iconmonstr-tree-20.svg);
}



.coming-up:after {
  content: "À venir";
  background-color: var(--gold-crayola);
  color: var(--oxford-blue);
  font-size: 0.6rem;
  border-radius: 20px;
  padding: 2px 5px;
  margin-left: 3px;
  vertical-align: top;
  white-space: nowrap;
}

#pricing h1 {
  margin-left: auto;
}

#pricing .body {
  display: flex;
}

#pricing .body:after {
  content: " ";
  justify-content: space-around;
  background-image: url(../images/payment.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  min-height: 250px;
  // height: 200px;
  background-size: cover;
  margin-left: 3rem; 
}

.homepage #topbar-menu a {
  color: var(--oxford-blue)
}

.homepage #topbar-nav li + li { 
  margin-left: 2rem;
}

.homepage #topbar-menu-burger span {
  background-color: var(--oxford-blue);
  // letter-spacing: 3px;
}
@media screen and (max-width: $mobile-breaking-point) {
  #splash-screen {
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }

  #splash-screen div.cta {
    margin-top: 2rem;
    text-align: center;
  }

  .description-body:before {
    display: none;
  }

  #pricing .body:after {
    display: none;
  }

  .features-body {
    flex-direction: column;
  }

  .features-group > h2:not(first-child) {
    padding-bottom: 0.5rem;
    padding-top: 1.5rem;
  }
}
