@import "../_base.scss";

.dropdown {
  margin: 0;
}

.dropdown__list {
  position: absolute;
  list-style: none;
  background-color: var(--pacific-blue);
  padding: 0;
  margin: 0;
  padding-bottom: 0.2rem;
  width: fit-content;
  opacity: 0;
  display: none;
  transition: all 250ms ease-in;
  z-index: 1;
  //   transition: opacity 5s ease;
}

.dropdown__toggle {
  padding: 0.25rem 1.5rem;
  background-color:  var(--alice-blue);
  outline: none;
}

.dropdown__item {
  // background-color: white;
  position: block;
  z-index: 1;
  opacity: 0;
  margin: 0 ;
  padding: 0.5rem 0.5rem;
  width: 10rem;
  color: var(--alice-blue);
  cursor: pointer;
  transition: all 250ms ease-in;
}

.dropdown__item.active {
  opacity: 1;
  transition: all 250ms ease-in;
}

.dropdown__list.active {
    opacity: 1;
    display: block;
    transition: all 250ms ease-in;

  }
  
.dropdown__item + .dropdown__item {
//   margin-bottom: 0.5rem;
  border-top: solid 1px rgba(0,0,0,0.2)
}
