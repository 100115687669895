header.userPage {
  background-color: var(--oxford-blue);
}

header.userPage #topbar-nav {
  padding: 1rem 3rem;
  height: 7rem;
  color: var(--super-light);
  max-width: 1200px;
  // width: 100%;
  margin: auto;
}

header.userPage .logo-menu {
  align-items: center;  
  display: grid;
  grid-template-columns: 291px 1fr;
}

header.homepage #topbar-nav {
  padding: 1rem 3rem;
  display: grid;
  grid-template-columns: 300px 1fr;
  align-items: center;  
  height: 5rem;
  max-width: 1200px;
  margin: auto;
  width: 100%;
  color: var(--oxford-blue);
}

header.homepage a {
  text-decoration: none;
  // text-decoration-style: solid;
  // text-decoration-color:var(--primary);
  color: var(--oxford-blue);
}

#topbar-nav #logo {
  height: 45px;
  // width: 300px;
  padding-right: 3rem;
  z-index: 2;
}

.cart-and-menu {
  display: flex;
  justify-self: flex-end;
}

#topbar-menu {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  align-self: center;

  .topbar-submenu {
    position: relative;
    left: 10px;
    // border: 1px solid var(--dark);
    padding: 5px 5px;
    border-radius: 5px;
    opacity: 0;
    outline: none;
  }

  .dropdown__toggle {
    border: 0;
    box-shadow: none;
    background-color: inherit;
    color: var(--super-light);
    letter-spacing: 3px;
    font-weight: normal;
    padding: 0;
    outline: none;
    cursor: pointer;
  }
}

#topbar-menu li {
  list-style: none;
  color: var(--oxford-blue);
  // letter-spacing: 3px;
}

#topbar-menu a {
  color: var(--super-light);
}

#topbar-menu li:only-child {
  margin-left: auto;
  align-items: center;
}

#topbar-menu-burger {
  display: none;
  align-self: center;
  z-index: 2;
}

#topbar-menu-burger span {
  display: block;
  width: 25px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  margin-left: auto;
  background: var(--super-light);
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0px;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#topbar-nav .cart {
  position: relative;
  padding-right: 1.5rem;
}

#topbar-nav a .cart .badge {
  background-color: var(--pacific-blue);
  background-size: 0.5rem 0.5rem;
  border-radius: 15px;
  padding: 0 0.25rem;
  font-size: 0.75rem;
  position: absolute;
  top: -1rem;
  color: var(--alice-blue);
}

#user-menubar {
  background-color: var(--pacific-blue);
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}   

#user-menubar a {
  color: var(--super-light)
}   

#user-menubar-nav {
  padding: 1rem 3rem;
  max-width: 1200px;
  margin: auto;
  width: 100%;
}

#user-menubar-nav ul {
  display: flex;
}

#user-menubar-nav li {
  list-style: none;
  // color: var(--oxford-blue);
  // letter-spacing: 3px;
}


#user-menubar-nav li + li { 
  margin-left: 2rem;
}

#user-menubar-nav a:visited { 
  color: white;
}


@media screen and (max-width: $mobile-breaking-point) {

  #topbar-menu {
    opacity: 0;
    position: absolute;
    width: 300px;
    display: none;

    .dropdown__toggle {
      color: var(--oxford-blue);
    }
  }

  #topbar-menu-burger {
      display: block;
      cursor: pointer;
  }

  // #topbar-nav {
  //   align-items: center; 
  //   width:100%;
  // }

  // #topbar-menu {
  //   position: absolute;
  //   top:5rem;
  //   right:0rem;
  //   padding-right: 3rem;
  //   // padding-top: 1rem;
  //   padding-bottom: 3rem;
  //   grid-column: 1 / 3;
  //   visibility: none;
  //   opacity: 0;
  //   width: 100%;
  //   z-index: 1;
  //   background-color: var(--gold-crayola);
  //   box-shadow: 2px 2px 2px rgba(0,0,0,0.4);

  //   li a {
  //     line-height: 1.5rem;
  //     // color: var(--oxford-blue);
  //     position: relative;
  //     background-color: inherit;
  //     opacity: 1;
  //     font-weight: bold;
      
  //   }

  //   li + li {
  //     margin-top: 0.5rem;
  //   }

  //   .dropdown__menu {
  //     background-color: transparent;
  //   }

  //   .dropdown__toggle {
  //     color: var(--dark);
  //     font-size: inherit;
  //     // font-weight: bold;
  //     // cursor: pointer;
  //   }

  //   .dropdown__item {
  //     color: var(--dark);
  //     font-size: inherit;
  //     font-weight: bold;
  //     cursor: pointer;
  //   }
  // }

  // #topbar-nav.menu-active {
  //   background-color: var(--gold-crayola);
  //   transition: all 250ms ease;
  // }

  .homepage #topbar-nav.menu-active #topbar-menu {
    margin-top: -2rem;
  }

  #topbar-nav.menu-active #topbar-menu {
    flex-direction: column;
    opacity: 1;
    display: block;
    transition: all 250ms ease;
    background-color: var(--super-light);
    padding-top: 12rem;
    padding-bottom: 1rem;
    // margin-top: 6rem;
    margin-right: 1rem;
    right:0;
    border: 1px solid var(--oxford-blue);;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    box-shadow: 2px 2px 3px 0px var(--oxford-blue);
    z-index: 1;

    .dropdown__toggle {
      display: block;
      padding-left: 2rem;
      letter-spacing: normal;
      font-weight: bold;
      font-size: 1rem;
      text-align: left;
      cursor: default;
    }

    .dropdown__list {
      background-color: transparent;
      opacity:1;
      display:block;
      position: relative;
    }

    .dropdown__item {
      background-color: transparent;
      opacity:1;
    }

    .nav-item {
      display: block;
      letter-spacing: normal;
      font-size: 1rem;
      text-align: left;
      cursor: default;
      color: var(--oxford-blue);
      margin-left: 1rem;
      margin-right: 1rem;
      padding-left: 1rem;
    };

    .nav-item:hover {
      background-color: var(--pacific-blue);
      text-decoration: none;
      border-radius: 25px;
      color: var(--super-light)
    };

  }

  #topbar-menu-burger.toggle span.line1 {
    transform-origin: center left;
    transform: rotate(+45deg);
    background-color: var(--oxford-blue);
  }
  #topbar-menu-burger.toggle span.line2 {
    opacity: 0;
    background-color: var(--oxford-blue);
  }
  #topbar-menu-burger.toggle span.line3 {
    transform-origin: center left;
    transform: rotate(-45deg);
    background-color: var(--oxford-blue);
    // background-color: var(--light);
  }


  #user-menubar-nav ul {
    display: block;
  }

}
