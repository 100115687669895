.splash-screen {
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .logo {
    width: 50%;
    align-self: center;
    padding-bottom: 3rem;
  }

  form {
    padding: 3rem 0;
    width: 60%;
    align-self: center;
  }

  .error {
    color: red;
    padding-bottom: 1.5rem;
  }

}

.splash-screen button {
  margin: 2rem 2rem;
  // background-color: red;
}

@media screen and (max-width: $mobile-breaking-point) {
  .splash-screen {
    .logo {
      width: 80%;
    }

    form {
      padding: 3rem 1rem;
      width: 100%;
    }

    .forgot-password {
      padding: 2rem 0;
    }
  }
}
