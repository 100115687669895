
@import '../_base.scss';

%button-defaults {
    padding: 0.5em 2em;
    border-radius: 10px;
    font-weight: bold;
    border-width: 0;
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    outline: none;
  }

// Size

.btn--small {
    font-size: 0.75rem;
}

.btn--large {
    font-size: 1.25rem;
}

.btn--normal {
    font-size: 1rem;
}

// Variant

.btn--primary {
    @extend %button-defaults;
    background-color: var(--pacific-blue);
    color: var(--alice-blue);
}

.btn--secondary {
    @extend %button-defaults;
    background-color: var(--rhythm);
    color: var(--alice-blue);
}

// .btn--cta {
//     @extend %button-defaults;
//     background-color: var(--secondary);
//     color: var(--light);
//     padding: 2em 2.5em;
// }

.btn--link {
    text-decoration: underline;
    background-color: transparent;
    border: none;
    color: var(--oxford-blue);
    cursor: pointer;
    outline: none;
}

button svg {
    margin-right: $iconTextMargin;
}