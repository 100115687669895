.ck-rounded-corners .ck.ck-editor__editable:not(.ck-editor__nested-editable), .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners {
    padding:0;
}

.ck.ck-editor__editable_inline>:first-child {
    margin: 0;
}

.cms {
    position: relative;
    margin-bottom: 2rem;
}

.cms-button {
    position: absolute;
    right: 0px; 
    bottom: 0px;
    opacity: 0.6;
    font-size: 0.5rem;
    padding: 5px 10px;  
    // float:right;
}


.ck-content h2 {
    margin: 0;
    margin-top: 2rem;
}