footer {
  margin-top: 1rem;
  background-color: var(--oxford-blue);
}

footer .body {
  display: flex;
  justify-content: space-between;
  color: var(--alice-blue);
  padding: 3rem;
}

footer h1 {
  font-size: 1.5rem;
}

footer .left {
  max-width: 40%;
}

footer p {
  font-size: 0.75rem;
}

footer a {
  color: var(--super-light);
}


@media screen and (max-width: $mobile-breaking-point) {
  footer .body {
    text-align: center;
    flex-direction: column;
    // margin-top: 2em;
  }

  footer .left {
    max-width: none;
    width: 100%;
    margin-bottom: 1.5rem;
  }
}
