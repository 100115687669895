@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

$mobile-breaking-point: 768px;
$iconTextMargin: 0.5rem;

// :root {
//   --primary: #7e8c82;
//   --primary-faded: rgba(126, 140, 130, 0.9);
//   --secondary: #8c7070;
//   --light: #d9d9d9;
//   --dark: #4c4c4c;
// }

// :root {
//   --primary: #3aafa9;
//   --primary-faded: rgba(126, 140, 130, 0.9);
//   --secondary: #2b7a78;
//   --light: #def2f1;
//   --super-light: #feffff;
//   --dark: #17252a;
// }

:root {
  --super-light: #f9f9f9;
  --alice-blue: #dcedffff;
  --rhythm: #7c7287ff;
  --oxford-blue: #0a122aff;
  --gold-crayola: #f9c784ff;
  // --pumpkin: #fc7a1eff;
  // --imperial-red: #f71735ff;
  // --pumpkin: #01A7C2;
  --pacific-blue: #01a7c2ff;
  --light: #0a122a99;

}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
  }