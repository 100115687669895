.participants {
  .card__deck {
    display: none;
  }

  table {
    display: table;
  }
}

// .category .card__data {
//   color: var(--dark);
// }

@media screen and (max-width: $mobile-breaking-point) {
  .participants {
    .card__deck {
      display: block;
    }

    table {
      display: none;
    }
  }
}

@media screen and (max-width: 600px) {
  .sidebar{
  display:none;
  }}