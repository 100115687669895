@import '../_base.scss';


.card__deck {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
    // margin: 0.5rem 0 2rem 0;
}

.card__deck.four-columns {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.card__deck.three-columns {
    grid-template-columns: 1fr 1fr 1fr;
}

.card {
    // background-color: var(--light);
    border: 1px solid var(--oxford-blue);
    border-radius: 10px;
    padding: 1rem 1.5rem;
}

.card a {
    // color: var(--oxford-blue);
}

.card__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card__header h1 {
    color: var(--oxford-blue);
    font-size: 1.5rem;
    margin: 0;
    margin-bottom: 1rem;
}

.card__data {
    font-size: 1rem;
    color:var(--light);
    // margin-left: 1rem;
    // margin-right: 1rem;
    // padding-bottom: 0.5rem;
    // margin-top: 1rem;
    margin: 0.5rem 1rem;
    margin-top: 0;

    svg {
        margin-right: $iconTextMargin;
    }
}


.card__body {

    // margin-bottom: 1rem;
    color: var(--oxford-blue);

    .event-type {
        font-size: 1.5rem;
    }

    svg {
        margin-right: $iconTextMargin;
    }
}

.card__data + .card__body {
    padding-top: 0.5rem;
    margin-top: 0.5rem;
    border-top: 1px solid var(--alice-blue);

}

.card__body + .card__footer {
    padding-top: 0.5rem;
    border-top: 1px solid var(--alice-blue);
}

.card__footer {

    width: 100%;
    display: flex;
    justify-content: space-around;

    button {
        margin: 0.5rem 1rem;
    }
    button + button {
        margin-left: 0.25rem
    }
}



@media screen and (max-width: $mobile-breaking-point) {

    .card__deck {
        display: block;
        // grid-template-columns: 1fr 1fr;
        // column-gap: 1rem;
        row-gap: 1rem;
        margin: 0.5rem 0 2rem 0;
    }

    .card:not(:last-child) {
        margin-bottom: 1rem;
    }

}