@import '../_base.scss';

.modal__bg {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10rem;
  justify-content: space-around;
}

.modal {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: space-around;
  // width: 500px;
  // height: 30%;
  background-color: white;
  border-radius: 5px;
  padding: 1rem 2rem;
  margin-bottom: -10rem;
  overflow-y: auto;
}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 1rem; 
}

.modal__header > h1 {
  color: var(--pacific-blue);
  font-size: 1.5rem;
  font-weight: bold;
  border-bottom: 4px solid var(--alice-blue);
  width: fit-content;
  display: flex;
  margin:0;

}

.modal__body {
  height: max-content;
}

// .modal-header::after {
//   content: '';
//   background-image: url(../images/icons/iconmonstr-x-mark-1.svg);
//   background-repeat: no-repeat;
//   background-size: contain;
//   width: 1rem;
//   height: 1rem;
// }

.modal__footer {
    margin-top: 3rem;
  display: flex;
  // justify-content: space-between;
  // width: 30%;
  float: right;
  align-items: center;
}

.modal__footer *:not(:last-child) {
  margin-right: 1rem;
}
